<template>
    <div class="main">
        <ul class="box">
            <li class="list col7-3" :style="item.id==1 &&'background:#284c91;color:#fff;'||''" @click="()=>detail(item.id)" :key="item.title" v-for="item in data">
                <div class="left col7">
                    <p class="time">{{item.time}}</p>
                    <h3 class="title">{{item.title}}</h3>
                    <section class="content">
                       {{item.content[1]}}
                    </section>
                </div>
                <div class="right col3" @click.stop="()=>false">
                    <ImagePreview style="max-height:300px;overflow: hidden;" 
                    class="img" :src="require('../../assets/News/News/'+item.img[1])" :srcList="[require('../../assets/News/News/'+item.img[1])]"></ImagePreview>
                </div>
            </li>
        </ul>
        <el-pagination
        background
        layout="prev, pager, next"
        :total="data.length">
        </el-pagination>
    </div>
</template>
<script>
export default {
    computed:{
        data(){
          let data = JSON.parse(JSON.stringify(this.$store.state.newsData))
          data.map(item=>{
              item.img = item.content.filter(it=>it[0]=='img').length?item.content.filter(it=>it[0]=='img')[0]:''
              item.content = item.content.filter(it=>{
                if(it[0]=='text'&&it[1].length>20){
                  return it
                }
              })[0]
              
              return item 
            })
            console.log(data)
            return data;
        }
    },
    methods:{
        detail(id){
            this.$router.push({
                path:'/news/news_detail/'+id
            })
        }
    }
}
</script>
<style scoped src="../../layout.css"></style>
<style scoped src="./common.css">

</style>